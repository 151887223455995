import Repository from './Repository'

export default {

    addLearner(groupId, firstname, surname, username, password) {
        var response = Repository.post('/group/addLearner', {
            groupid: groupId,
            firstname: firstname,
            surname: surname,
            username: username,
            password: password
        });
        return response;
    },

    getGroup(groupId) {
        var response = Repository.get('/group/getGroup', {
                params:{
                    groupid: groupId              
                } 
            }
        );
        return response;
    },

    listMyGroups() {
        var response = Repository.get('/group/listMyGroups', {
            params:{          
            }                 
        });
        return response;
    },

    newGroup(name) {
        var response = Repository.post('/group/newGroup', {
            name: name
        });
        return response;
    },

    renameGroup(groupId, name) {
        var response = Repository.post('/group/renameGroup', {
                groupid: groupId,
                name: name            
            }
        );
        return response;
    },

};

import Repository from './Repository'

export default {

    uploadFlipbookPDF(lessonWidgetId, file) {
        let formData = new FormData()
        
        formData.append("pdffile", file);
        formData.append("lessonwidgetid", lessonWidgetId);

        var response = Repository.post('https://api.metricforms.com/util/uploadflipbookpdf.php', 
            formData,
            {
                headers: { 
                    'Content-Type': 'multipart/form-data'
                }                    
            }                
        );
        
        return response;
    },

    uploadImage(file) {
        let formData = new FormData()
        
        formData.append("imagefile", file);

        //alert('upload pdf');

        var response = Repository.post('https://api.metricforms.com/util/uploadimage.php', 
            formData,
            {
                headers: { 
                    'Content-Type': 'multipart/form-data'
                }                    
            }                
        );
        
        return response;
    },

    uploadPDF(file) {
        let formData = new FormData()
        
        formData.append("pdffile", file);

        //alert('upload pdf');

        var response = Repository.post('https://api.metricforms.com/util/uploadpdf.php', 
            formData,
            {
                headers: { 
                    'Content-Type': 'multipart/form-data'
                }                    
            }                
        );
        
        return response;
    }

};

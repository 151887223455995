import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    component: () => import('@/views/SignIn.vue'), 
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
    },        
  },

  { 
    path: '/course/:courseid', 
    component: () => import('@/views/Course.vue'), 
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },    
  },

  { 
    path: '/coverpages', 
    component: () => import('@/views/admin/CoverPages.vue'), 
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },    
  },

  { 
    path: '/courses', 
    component: () => import('@/views/admin/Courses.vue'), 
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },    
  },

  { 
    path: '/exercise/:lessonwidgetid/:exerciseid/:questions', 
    component: () => import('@/views/Exercise.vue'), 
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },    
  },

  { 
    path: '/group/:groupid', 
    component: () => import('@/views/Group.vue'), 
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },    
  },

  { 
    path: '/mycourses', 
    component: () => import('@/views/MyCourses.vue'), 
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },    
  },

  { 
    path: '/mygroups', 
    component: () => import('@/views/MyGroups.vue'), 
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },    
  },

  { 
    path: '/printcoverpage', 
    component: () => import('@/views/admin/PrintCoverPage.vue'), 
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },    
  },

  { 
    path: '/users', 
    component: () => import('@/views/admin/Users.vue'), 
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },    
  },

  { 
    path: "*", 
    component: () => import('@/views/MyCourses.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },        
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }  
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => (record.meta.requiresAuth && !record.meta.requiresAdmin) )) {
    
    // this route requires auth, check if logged in
    // if not, redirect to sign in page.
    if (!store.state.authenticated) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }

  } else if (to.matched.some(record => (record.meta.requiresAdmin) )) {

    if (!store.state.user.administrator) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }

  } else if ((to.path == '/') && store.state.authenticated) {
    next({
      path: '/mycourses',
      query: { redirect: to.fullPath }
    })
  } else {
    next() // make sure to always call next()!
  }
});

export default router

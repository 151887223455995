import axios from 'axios'
import errorUtils from '@/utils/errorUtils'
import userUtils from '@/utils/userUtils'
import store from '@/store'
import router from '@/router'

const baseURL = 'https://api.metricforms.com';
//const baseURL = 'http://localhost:8080/mfapi';

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        'content-type': 'application/json'
    },    
});

axiosInstance.interceptors.request.use(function(config) {
    errorUtils.clearError(); // first we clear last error
    const token = userUtils.getToken();
    if (token != '') {
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
}, function(err) {
    return Promise.reject(err);
});

axiosInstance.interceptors.response.use(
    response => {

        if (response.data.success === false) {
            if (response.data.error_code === 11) { // JWT expired.
                store.commit('signOut'); 
                router.push("/");                
            }             
        }

        return Promise.resolve(response);

      //if (response.status === 200 || response.status === 201) {
      //  return Promise.resolve(response);
      //} else {
      //  return Promise.reject(response);
      //}
    },
  /*error => {
      if (error.response.status) {
        switch (error.response.status) {
          case 400:
           
           //do something
            break;
        
          case 401:
            alert("session expired");
            break;
          case 403:
            router.replace({
              path: "/login",
              query: { redirect: router.currentRoute.fullPath }
            });
             break;
          case 404:
            alert('page not exist');
            break;
          case 502:
           setTimeout(() => {
              router.replace({
                path: "/login",
                query: {
                  redirect: router.currentRoute.fullPath
                }
              });
            }, 1000);
        }
        return Promise.reject(error.response);
      }
    }*/
  );

export default axiosInstance;

import Repository from './Repository'

export default {

    listLocales() {
        var response = Repository.get('/locale/listLocales', {
            params:{          
            }             
        });
        return response;
    },

};

import Repository from './Repository'

export default {

    listPublishers() {
        var response = Repository.get('/publisher/listPublishers', {
            params:{          
            }             
        });
        return response;
    },

    /*register(username, email, password, firstname, surname) {
        var response = Repository.post('/user/register', {
            username: username,
            email: email,
            password: password,
            firstname: firstname,
            surname: surname
        });
        return response;
    },*/


};

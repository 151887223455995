import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authenticated: false, 
    error: {
      message: ''
    },    
    token: '',
    user: {},
    lessonSelected: {},
    lessonIndex: 0,
    tabIndex: 0,
    exploreCourse: false,
    lessonItems: [],
    courseTitle: '',
    publisherName: '',
    courseModerator: false,
    courseId: 0,
  },

  mutations: {

    selectLesson(state, selectInfo) {
      state.lessonSelected['c' + selectInfo.courseId] = selectInfo.lessonIndex;
      state.lessonSelected['ct' + selectInfo.courseId] = selectInfo.tabIndex;
    },

    signIn(state, token) {            
      state.token = token;
      // extract payload

      const payloadBase64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(Buffer.from(payloadBase64, 'base64').toString('utf8'));
      //console.log(payload);
      //alert(JSON.stringify(payload));

      //var utf8encoded = Buffer.from(base64encoded, 'base64').toString('utf8');
      //let buff = new Buffer(data, 'base64');
      //let text = buff.toString('ascii');

      /*const base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
      var payload = decodeURIComponent(atob(base64).split('').map(
        function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));*/
      state.user = payload.user;
      state.authenticated = true;
    },    

    signOut (state) {
      state.user = {};
      state.token = '';
      state.authenticated = false;
    }

  },

  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})

import CourseRepository from './CourseRepository'
import GroupRepository from './GroupRepository'
import LocaleRepository from './LocaleRepository'
import PublisherRepository from './PublisherRepository'
import ResourceRepository from './ResourceRepository'
import UserRepository from './UserRepository'

const repositories = {
    course: CourseRepository,
    group: GroupRepository,
    locale: LocaleRepository,
    publisher: PublisherRepository,
    resource: ResourceRepository,
    user: UserRepository,
};

export const RepositoryFactory = {
    get: name => repositories[name]
};

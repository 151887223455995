import Repository from './Repository'

export default {

    activateCourse(email, courseId) {
        var response = Repository.post('/user/activateCourse', {
            email: email,
            courseid: courseId
        });
        return response;
    },

    authenticate(username, password) {
        var response = Repository.post('/user/authenticate', {
            username: username,
            password: password
        });
        return response;
    },

    changePassword(userId, password) {
        var response = Repository.post('/user/changePassword', {
            userid: userId,
            password: password
        });
        return response;
    },

    getCoverPagePdf(userScoreId) {
        var response = Repository.get('/user/getcoverpagepdf', {
            params: { 
                userscoreid: userScoreId
            }               
        });
        return response;
    },

    listSessions() {
        var response = Repository.get('/user/listSessions', {
            params:{          
            }             
        });
        return response;
    },

    listUsers() {
        var response = Repository.get('/user/listUsers', {
            params:{          
            }             
        });
        return response;
    },

    register(username, email, password, firstname, surname) {
        var response = Repository.post('/user/register', {
            username: username,
            email: email,
            password: password,
            firstname: firstname,
            surname: surname
        });
        return response;
    },

    update(userId, username, email, firstname, surname) {
        var response = Repository.post('/user/update', {
            userid: userId,
            username: username,
            email: email,
            firstname: firstname,
            surname: surname
        });
        return response;
    },    

};

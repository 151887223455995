import Repository from './Repository'

export default {

    appendWidget(lessonTabId, widgetType) {
        var response = Repository.post('/course/appendWidget', {
            lessontabid: lessonTabId,
            widgettype: widgetType
        });
        return response;
    },

    clearActions(lessonTabId) {
        var response = Repository.post('/course/clearActions', {
            lessontabid: lessonTabId
        });
        return response;
    },

    clearLessonComplete(lessonId) {
        var response = Repository.post('/course/clearLessonComplete', {
            lessonid: lessonId
        });
        return response;
    },

    deleteCourse(courseId) {
        var response = Repository.post('/course/deleteCourse', {
                courseid: courseId              
            }
        );
        return response;
    },

    deleteLesson(lessonId) {
        var response = Repository.post('/course/deleteLesson', {
                lessonid: lessonId          
            }
        );
        return response;
    },

    deleteLessonTab(lessonTabId) {
        var response = Repository.post('/course/deleteLessonTab', {
                lessontabid: lessonTabId       
            }
        );
        return response;
    },

    deleteWidget(lessonWidgetId) {
        var response = Repository.post('/course/deleteWidget', {
            lessonwidgetid: lessonWidgetId
        });
        return response;
    },

    duplicateCourse(courseId) {
        var response = Repository.post('/course/duplicateCourse', {
                courseid: courseId              
            }
        );
        return response;
    },

    getCourse(courseId) {
        var response = Repository.get('/course/getCourse', {
                params:{
                    courseid: courseId              
                } 
            }
        );
        return response;
    },

    getExercise(lessonWidgetId, exerciseId) {
        var response = Repository.get('/course/getExercise', {
                params:{
                    lessonwidgetid: lessonWidgetId,
                    exerciseid: exerciseId         
                } 
            }
        );
        return response;
    },

    getLessonTabWidgets(lessonTabId) {
        var response = Repository.get('/course/getLessonTabWidgets', {
                params:{
                    lessontabid: lessonTabId              
                } 
            }
        );
        return response;
    },

    listCourses() {
        var response = Repository.get('/course/listCourses', {
            params:{          
            }             
        });
        return response;
    },

    listMyCourses() {
        var response = Repository.get('/course/listMyCourses', {
            params:{          
            }                 
        });
        return response;
    },

    markLessonComplete(lessonId) {
        var response = Repository.post('/course/markLessonComplete', {
            lessonid: lessonId
        });
        return response;
    },

    moveWidgetUp(lessonWidgetId) {
        var response = Repository.post('/course/moveWidgetUp', {
            lessonwidgetid: lessonWidgetId
        });
        return response;
    },

    moveWidgetDown(lessonWidgetId) {
        var response = Repository.post('/course/moveWidgetDown', {
            lessonwidgetid: lessonWidgetId
        });
        return response;
    },

    moveWidgetToTab(lessonWidgetId, lessonTabId) {
        var response = Repository.post('/course/moveWidgetToTab', {
            lessonwidgetid: lessonWidgetId,
            lessontabid: lessonTabId
        });
        return response;
    },

    moveLessonDown(lessonId) {
        var response = Repository.post('/course/moveLessonDown', {
                lessonid: lessonId          
            }
        );
        return response;
    },

    moveLessonUp(lessonId) {
        var response = Repository.post('/course/moveLessonUp', {
                lessonid: lessonId          
            }
        );
        return response;
    },

    newCourse(title, publisherId, localeId) {
        var response = Repository.post('/course/newCourse', {
            title: title,
            publisherid: publisherId,
            localeid: localeId
        });
        return response;
    },

    newLesson(courseId, title) {
        var response = Repository.post('/course/newLesson', {
            courseid: courseId, 
            title: title
        });
        return response;
    },

    newLessonTab(lessonId) {
        var response = Repository.post('/course/newLessonTab', {
            lessonid: lessonId
        });
        return response;
    },

    rateLesson(lessonId, rating) {
        var response = Repository.post('/course/rateLesson', {
            lessonid: lessonId,
            rating: rating
        });
        return response;
    },

    renameCourse(courseId, title) {
        var response = Repository.post('/course/renameCourse', {
                courseid: courseId,
                title: title            
            }
        );
        return response;
    },

    renameLesson(lessonId, title) {
        var response = Repository.post('/course/renameLesson', {
                lessonid: lessonId,
                title: title            
            }
        );
        return response;
    },

    renameLessonTab(lessonTabId, name) {
        var response = Repository.post('/course/renameLessonTab', {
                lessontabid: lessonTabId,
                name: name            
            }
        );
        return response;
    },

    submitAction(lessonWidgetId, action) {
        var response = Repository.post('/course/submitAction', {
            lessonwidgetid: lessonWidgetId,
            action: action
        });
        return response;
    },    

    submitScore(lessonTabId, total, correct) {
        var response = Repository.post('/course/submitScore', {
            lessontabid: lessonTabId,
            total: total,
            correct: correct
        });
        return response;
    },
    
    updateWidget(lessonWidgetId, instance) {
        var response = Repository.post('/course/updateWidget', {
            lessonwidgetid: lessonWidgetId,
            instance: instance
        });
        return response;
    },    

};

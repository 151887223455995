import store from '@/store'

export default { 

    clearError() {
        store.state.error.message = '';
    },

    errorMessage() {
        return store.state.error.message;
    },

    hasError() {
        const result = (store.state.error.message != '');
        return result;
    },

    showError(message) {
        store.state.error.message = message;
    },

};

<template>
  <v-app
    v-bind:class="{backdrop: !$store.state.authenticated}"
  >

    <v-app-bar
      app
      v-if="($store.state.authenticated) && ($route.path.substr(0, 10) !== '/exercise/')"  
      clipped-left
    >

      <v-app-bar-nav-icon
        v-if="exploreCourse"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <div class="d-flex align-center">
        <v-img
          alt="Metricforms"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          @click="myCourses()"
          style="cursor: pointer;"
        ></v-img>
      </div>

      <v-spacer></v-spacer>

      <v-menu
        v-if="$store.state.authenticated && ($route.path.substr(0, 15) !== '/printcoverpage')"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              style="color:#447744;"
            >mdi-account</v-icon>
            <span 
              class="menuText"
              style="color: #447744;"
            >{{$store.state.user.firstname}}</span>
          </v-btn>
        </template>
        <v-list>

          <v-list-item
            v-if="$store.state.authenticated"
          >
            <v-btn            
              text
              @click="myCourses()"
            >
              <v-icon
                style="color:#555555;"
              >mdi-bookshelf</v-icon>
              <span
                class="menuText"
              >My Courses</span>             
            </v-btn>  
          </v-list-item>

          <v-divider
            v-if="$store.state.user.administrator"
          >          
          </v-divider>

          <v-list-item
            v-if="$store.state.user.administrator"
          >
            <v-btn            
              text
              @click="systemUsers()"
              block
            >
              <v-icon
                style="color:#555555;"
              >mdi-account-multiple</v-icon>
              <span
                class="menuText"
              >Users</span>      
              <v-spacer />        
            </v-btn>            
          </v-list-item>

          <v-list-item
            v-if="$store.state.user.administrator"
          >
            <v-btn            
              text
              @click="coverPages()"
              block
            >
              <v-icon
                style="color:#555555;"
              >mdi-file-outline</v-icon>
              <span
                class="menuText"
              >Cover Pages</span>      
              <v-spacer />        
            </v-btn>            
          </v-list-item>
          
          <v-list-item
            v-if="$store.state.user.administrator"
          >
            <v-btn            
              text
              @click="systemCourses()"
              block
            >
              <v-icon
                style="color:#555555;"
              >mdi-bookshelf</v-icon>
              <span
                class="menuText"
              >Courses</span>      
              <v-spacer />        
            </v-btn>            
          </v-list-item>

          <v-divider
            v-if="$store.state.user.administrator"
          >          
          </v-divider>

          <v-list-item>
            <v-btn            
              text
              @click="signOut()"
              block
            >
              <v-icon
                style="color:#555555;"
              >mdi-logout</v-icon>
              <span
                class="menuText"
              >Sign Out</span>      
              <v-spacer />        
            </v-btn>            
          </v-list-item>

        </v-list>
      </v-menu>

    </v-app-bar>

    <v-navigation-drawer 
      v-if="exploreCourse"
      v-model="drawer"
      app
      clipped
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{courseTitle}}</v-list-item-title>
          <v-list-item-subtitle>By {{publisherName}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list 
        nav
      >
        <v-list-item-group
          v-model="$store.state.lessonIndex"
          color="primary"
        >      
          <v-list-item 
            v-for="(item, index) in lessonItems" 
            :key="index" 
            link
            @click="clickLessonItem(item)"
          >

            <v-menu
              v-if="courseModerator"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"              
                  style="cursor: pointer;"
                >mdi-dots-vertical</v-icon>&nbsp;
              </template>
              <v-list>
                <v-list-item @click="renameLesson(item)" style="cursor: pointer;"><v-list-item-title>Rename</v-list-item-title></v-list-item>
                <v-list-item @click="moveLessonUp(item, index)" style="cursor: pointer;"><v-list-item-title>Move Up</v-list-item-title></v-list-item>
                <v-list-item @click="moveLessonDown(item, index)" style="cursor: pointer;"><v-list-item-title>Move Down</v-list-item-title></v-list-item>
                <v-list-item @click="deleteLesson(item, index)" style="cursor: pointer;"><v-list-item-title>Delete</v-list-item-title></v-list-item>
              </v-list>
            </v-menu>   

            <v-list-item-content>
              <v-list-item-title>{{ (index + 1) + '. ' + item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-item 
          v-if="courseModerator"
          link
          @click="dialogNewLesson = true"
        >
          <v-list-item-icon>
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>New Lesson</v-list-item-title>
          </v-list-item-content>
        </v-list-item>   

      </v-list>
    </v-navigation-drawer>

    <v-main>

      <v-alert
        v-model="error"
        border="left"
        dense
        close-text="Close Alert"
        type="error"
        dark
        dismissible
      >
        {{errorMessage}}
      </v-alert>

      <router-view/>

    </v-main>

    <!-- Dialog: New Lesson -->

    <v-dialog
      v-model="dialogNewLesson"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          New Lesson
        </v-card-title>

        <v-card-text>

          <v-form
            ref="form"
          >

            <v-text-field
              v-model="lessonTitle"
              label="Title"
            ></v-text-field>         

          </v-form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogNewLesson = false"
          >
            Cancel
          </v-btn>          
          <v-btn
            color="primary"
            text
            @click="newLesson"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import errorUtils from '@/utils/errorUtils'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import { eventBus } from "@/main";

const CourseRepository = RepositoryFactory.get('course');

export default {
  name: 'App',

  data: () => ({  
    dialogNewLesson: false,
    lessonTitle: '',
    drawer: true,
    selectedLessonItem: 0,
  }),

  computed: {
    error () {
      var result = errorUtils.hasError();
      result = result && (this.$router.history.current.path !== '/');
      return result;
    },
    errorMessage () {
      return errorUtils.errorMessage();
    },
    lessonItems () {
      return this.$store.state.lessonItems;
    },
    courseTitle() {
      return this.$store.state.courseTitle;
    },
    publisherName() {
      return this.$store.state.publisherName;
    },
    exploreCourse() {
      return this.$store.state.exploreCourse;
    },
    courseModerator() {
      return this.$store.state.courseModerator;
    },
    courseId() {
      return this.$store.state.courseId;
    }       
  },

  mounted() {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var font;
    var w; // eslint-disable-line
  
    // preload fonts

    ctx.font = '10px ABC Junior Typing';		
    w = ctx.measureText(font).width; 

    ctx.font = '10px JetBrains Mono';		
    w = ctx.measureText(font).width;     

    this.$store.state.exploreCourse = false;
  },

  methods: {

    clickLessonItem(item) {  
      this.$store.state.tabIndex = 0;       
      this.$store.state.lessonIndex = item.lessonIndex;
      eventBus.$emit("select-lesson", item.lessonIndex);
    },

    myCourses() { 
      if (this.$route.path !== '/mycourses') {
        this.$router.push("/mycourses");
      } 
    },

    myGroups() { 
      if (this.$route.path !== '/mygroups') {
        this.$router.push("/mygroups");
      } 
    },

    async newLesson() {
      const { data } = await CourseRepository.newLesson(
        this.courseId,
        this.lessonTitle
      );
      if (data.success) {                 
        window.location.reload();
      } else {
        errorUtils.showError(data.message);
      }
    },

    async renameLesson(lesson) {
      let title = prompt("New lesson title", lesson.title);
      if (!(title == null || title == "")) {

        const { data } = await CourseRepository.renameLesson(lesson.lessonid, title);
        if (data.success) {  
          lesson.title = title;
        } else {          
          errorUtils.showError(data.message);
        }
      }
    },

    async moveLessonUp(lesson, index) {
      const { data } = await CourseRepository.moveLessonUp(lesson.lessonid);
      if (data.success) {  
        const tmpItem = this.$store.state.lessonItems[index - 1];
        this.$store.state.lessonItems[index - 1] = this.$store.state.lessonItems[index];
        this.$store.state.lessonItems[index] = tmpItem;
        this.$forceUpdate();
      } else {          
        errorUtils.showError(data.message);
      }
    },

    async moveLessonDown(lesson, index) {
      const { data } = await CourseRepository.moveLessonDown(lesson.lessonid);
      if (data.success) {  
        const tmpItem = this.$store.state.lessonItems[index];
        this.$store.state.lessonItems[index] = this.$store.state.lessonItems[index + 1];
        this.$store.state.lessonItems[index + 1] = tmpItem;
        this.$forceUpdate();
      } else {          
        errorUtils.showError(data.message);
      }
    },

    coverPages() { 
      if (this.$route.path !== '/coverpages') {        
        this.$router.push("/coverpages");
      } 
    },

    async deleteLesson(lesson, index) {
      const { data } = await CourseRepository.deleteLesson(lesson.lessonid);
      if (data.success) {  
        this.$store.state.lessonItems.splice(index, 1);
        this.$forceUpdate();
        this.$store.state.tabIndex = 0;       
        this.$store.state.lessonIndex = 0;
        eventBus.$emit("select-lesson", 0);        
      } else {          
        errorUtils.showError(data.message);
      }
    },

    signOut() {      
      errorUtils.clearError(); // first we clear last error
      this.$store.commit('signOut'); 
      this.$router.push("/");
    },

    systemCourses() { 
      if (this.$route.path !== '/courses') {        
        this.$router.push("/courses");
      } 
    },

    systemUsers() { 
      if (this.$route.path !== '/users') {        
        this.$router.push("/users");
      } 
    },

  }

};
</script>

<style>
  @font-face {
    font-family: 'ABC Junior Typing';
    src: 
      local('ABC Junior Typing'), 
      url('./fonts/abcjunior.ttf');
  }
  @font-face {
    font-family: 'Courgette';
    src: 
      local('Courgette'), 
      url('./fonts/Courgette-Regular.ttf');
  }  
  @font-face {				
    font-family: 'JetBrains Mono';
    src: 
      local('JetBrains Mono'), 
      url('./fonts/JetBrainsMono-Regular.woff2');
  }	  
</style>

<style scoped>
  .backdrop {
    background: url('./assets/backdrop.jpg')
      no-repeat center center fixed !important;
    background-size: cover;
  }

  .menuText {
    text-transform: none !important;
  }

</style>
